<template>
    <div ref="commentWrapper">
        <div 
            class="comment_text" 
            :class="!showEditor && 'place'">
            <template v-if="showEditor" >
                <div class="ck_wrap" :class="showEditorToolbar && 'show_toolbar'">
                    <Editor 
                        v-model="comment.text"
                        ref="editor"
                        commentEditor
                        :enterShifthHand="addHandler"
                        initFocus />
                </div>
                <div class="comment_text__footer">
                    <label
                        v-if="showFileUpload"
                        class="act_btn ant-btn flex items-center justify-center ant-btn-ui ant-btn-circle ant-btn-icon-only ant-btn-background-ghost ant-btn-sm"
                        @click="openFileModal">
                        <i class="fi flaticon fi-rr-clip"></i>
                    </label>
                    <!--<template v-if="showUsers">
                        <a-tooltip 
                            :[showTooltip.attribute]="showTooltip.visible"
                            :getPopupContainer="getPopupContainer"
                            title="Показать только">
                            <a-badge 
                                :count="readers.length"
                                :number-style="{
                                    backgroundColor: '#1c65c0'
                                }">
                                <a-button 
                                    type="ui" 
                                    shape="circle" 
                                    :ghost="readers.length > 0 ? false : true" 
                                    icon="fi-rr-user-add"
                                    class="ml-2"
                                    size="small"
                                    flaticon
                                    @click="openDrawer" />
                            </a-badge>
                        </a-tooltip>
                        <UserDrawer
                            v-model="readers"
                            title="Показать только"
                            multiple
                            :id="related_object"
                            hide
                            :modal="modal"
                            @input="updateUsers"/> 
                    </template>-->
                    <Emoji 
                        v-if="!isMobile && showEmoji" 
                        class="ml-2" 
                        size="small"
                        @click="selectEmoji" />
                    <a-button 
                        type="ui" 
                        ghost 
                        class="ml-2"
                        shape="circle"
                        :class="showEditorToolbar && 'active_btn'"
                        size="small"
                        flaticon
                        icon="fi-rr-message-text"
                        @click="toggleShowToolbar()" />
                    <a-dropdown 
                        :trigger="['click']" 
                        :getPopupContainer="getModalContainer">
                        <a-button 
                            type="ui" 
                            ghost 
                            class="ml-2"
                            shape="circle"
                            size="small"
                            flaticon
                            icon="fi-rr-settings" />
                        <a-menu slot="overlay">
                            <a-menu-item 
                                key="0" 
                                class="flex items-center" 
                                @click="setBlockLeft()">
                                <i 
                                    v-if="blockLeft" 
                                    class="mr-2 fi fi-rr-check" />
                                Блоки с левой стороны
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </div>
            </template>
            <div v-else class="comment_text__placeholder" @click="showEditorHandler()">
                {{ inputPlaceholder }}
            </div>
        </div>
        <template v-if="showEditor">
            <FileAttach 
                ref="fileAttach"
                :attachmentFiles="files"
                :maxMBSize="50"
                :oneUpload="oneUpload"
                :createFounder="createFounder"
                :getModalContainer="getModalContainer"
                :class="files.length && 'mt-2'"
                class="ml-2" />
            <div class="flex items-center ml-2 mt-2">
                <a-button 
                    v-if="editData"
                    type="primary" 
                    :loading="loading"
                    :class="isMobile && 'mr-1'"
                    @click="addHandler()">
                    Сохранить
                </a-button>
                <a-button 
                    v-else
                    type="primary" 
                    :loading="loading"
                    :block="isMobile"
                    @click="addHandler()">
                    Отправить
                </a-button>
                <a-button 
                    type="ui" 
                    ghost 
                    :block="isMobile"
                    :class="isMobile ? 'ml-1' : 'ml-2'"
                    @click="closeEditorHandler()">
                    Отменить
                </a-button>
            </div>
        </template>
    </div>
</template>

<script>
import Emoji from '@/components/Emoji'
//import UserDrawer from './UserDrawer.vue'
import eventBus from './eventBus'
import FileAttach from '@apps/vue2Files/components/FileAttach'
import Editor from '@apps/CKEditor/index.vue'
import eventBusGlob from '@/utils/eventBus'
export default {
    components: { 
        Emoji, 
        //UserDrawer, 
        FileAttach,
        Editor
    },
    props: {
        related_object: {
            type: [String, Number],
            required: true
        },
        model: {
            type: String,
            required: false
        },
        parent: {
            type: Object,
            default: null
        },
        showUsers: {
            type: Boolean,
            default: true
        },
        oneUpload: {
            type: Boolean,
            default: false
        },
        createFounder: {
            type: Boolean,
            default: true
        },
        showEmoji: {
            type: Boolean,
            default: true
        },
        showFileUpload: {
            type: Boolean,
            default: true
        },
        closeEditorFunc: {
            type: Function,
            default: () => {}
        },
        pushNewComment: {
            type: Function,
            default: () => {}
        },
        updateComment: {
            type: Function,
            default: () => {}
        },
        modal: {
            type: Boolean,
            default: false
        },
        inputPlaceholder: {
            type: String,
            default: 'Добавить комментарий'
        },
        editData: {
            type: Object,
            default: () => null
        },
        getModalContainer: {
            type: Function,
            default: () => document.body
        },
        setBlockLeft: {
            type: Function,
            default: () => {}
        },
        blockLeft: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            fileLoading: false,
            usersShow: false,
            showEditor: false,
            showEditorToolbar: false,
            files: [],
            readers: [],
            comment: {
                parent: this.parent ? this.parent.id : null,
                text: '',
                readers: [],
                related_object: this.related_object,
                model: this.model,
                comment_type: null,
                attachments: []
            }
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        showTooltip() {
            return {
                attribute: this.isMobile ? 'visible' : null,
                visible: this.isMobile ? false : null
            }
        }
    },
    created() {
        if(this.parent) {
            eventBusGlob.$emit('CLOSE_MAIN_FORM')
            if(!this.modal)
                this.showEditorHandler()
        }
        if(this.editData) {
            this.editInit()
        }
    },
    watch: {
        editData(val) {
            if(!val) {
                this.clear()
            } else {
                this.editInit()
            }
        }
    },
    methods: {
        editInit() {
            const editData = JSON.parse(JSON.stringify(this.editData))
            this.comment = {
                ...editData
            }
            if(editData.attachments?.length)
                this.files = editData.attachments

            if(!this.showEditor)
                this.showEditor = true
        },
        closeEditorHandler() {
            this.showEditor = false
            this.closeEditorFunc()
            this.clear()
        },
        clear() {
            this.files = []
            this.readers = []
            this.comment = {
                parent: this.parent ? this.parent.id : null,
                text: '',
                related_object: this.related_object,
                model: this.model,
                comment_type: null,
                attachments: []
            }
        },
        showEditorHandler() {
            if(!this.parent)
                eventBusGlob.$emit('CLOSE_RES_FORM')

            this.showEditor = true
            const tolbar = localStorage.getItem('showCommentToolbar')
            if(tolbar)
                this.showEditorToolbar = JSON.parse(tolbar)
        },
        toggleShowToolbar() {
            this.showEditorToolbar = !this.showEditorToolbar
            localStorage.setItem('showCommentToolbar', this.showEditorToolbar)
            this.$nextTick(() => {
                this.$refs.editor.editorFocus()
            })
        },
        updateUsers(val){
            this.comment.readers = val.map(el=> el.id)
        },
        openDrawer(){
            eventBus.$emit('open_user_comment_drawer', this.related_object)
        },
        getPopupContainer() {
            return this.$refs['commentWrapper']
        },
        selectEmoji(emoji) {
            this.$refs.editor.insertText(emoji.detail.unicode)
        },
        handleFileUpload() {
            const files = this.$refs[`com_file_${this.related_object}`].files
            if(files.length) {
                Array.prototype.forEach.call(files, async (item, i) => {
                    let formData = new FormData()
                    if(10 && ((files[i].size / 1024) / 1024) >= 10 ) {
                        this.$message.warning(this.$t('chat.file_size_error', {name: files[i].name, filesize: 10}))
                        return false
                    }
                    formData.append('upload', files[i])

                    try {
                        this.fileLoading = true
                        const {data} = await this.$http.post('/common/upload/', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        if(data?.length)
                            this.files.push(data[0])
                
                    } catch(e) {
                        this.$message.error(this.$t('chat.error'))
                    } finally {
                        this.fileLoading = false
                    }
                })
            }
        },
        dropInput(e) {
            if(e.dataTransfer?.files?.length) {
                this.$refs[`com_file_${this.related_object}`].files = e.dataTransfer.files
                this.handleFileUpload()
            }
        },
        async addHandler() {
            if(!this.loading) {
                if(this.comment.text.length || this.files?.length) {
                    try {
                        this.loading = true
                        const commentData = {...this.comment}
                        if(this.files?.length)
                            commentData.attachments = this.files.map(file => file.id)
                        if(this.editData) {
                            const { data } = await this.$http.put(`/comments/${commentData.id}/update/`, commentData)
                            if(data) {
                                this.$message.success('Комментарий обновлен')
                                //this.updateComment(data)
                                this.closeEditorHandler()
                            }
                        } else {
                            const { data } = await this.$http.post('/comments/create/', commentData)
                            if(data) {
                                this.$message.success('Комментарий добавлен')
                                //this.pushNewComment(data)
                                this.closeEditorHandler()
                            }
                        }
                    } catch(e) {
                        console.log(e)
                        this.$message.error('Ошибка')
                    } finally {
                        this.loading = false
                    }
                }
            }
        },
        openFileModal() {
            this.$nextTick(() => {
                this.$refs.fileAttach.openFileModal()
            })
        }
    },
    mounted() {
        eventBusGlob.$on('CLOSE_MAIN_FORM', () => {
            this.closeEditorHandler()
        })
    },
    beforeDestroy() {
        eventBusGlob.$off('CLOSE_MAIN_FORM')
    }
}
</script>

<style lang="scss" scoped>
.comment_text{
    border: 1px solid var(--borderColor);
    border-radius: var(--borderRadius);
    background: #ffffff;
    &.place{
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        &:hover{
            border-color: var(--blue);
        }
    }
    &__placeholder{
        cursor: pointer;
        padding: 10px 15px;
        color: #000000;
        opacity: 0.4;
        text-align: left;
        background: #ffffff;
    }
    &__footer{
        display: flex;
        justify-content: flex-end;
        padding: 2px 15px 3px 15px;
        &::v-deep{
            .ant-badge-count{
                font-size: 10px !important;
                min-width: 17px;
                height: 17px;
                padding: 0 6px;
                line-height: 17px;
            }
        }
    }
    .ck_wrap{
        &:not(.show_toolbar){
            &::v-deep{
                .ck-editor__top{
                    display: none;
                }
            }
        }
    }
    &::v-deep{
        .ck-dropdown__panel.ck-dropdown__panel_ne, .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se{
            left: initial;
            right: 0;
        }
        .ant-btn{
            &.active_btn{
                color: var(--blue);
            }
        }
        .ck-toolbar,
        .ck-content{
            border: 0px;
            max-height: 300px;
        }
        .ck-content{
            &.ck-focused{
                border: 0px;
                box-shadow: initial;
            }
            &.ck-editor__editable_inline{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .ck.ck-code-block-dropdown .ck-dropdown__panel{
            max-height: 100px;
        }
        .ck.ck-color-grid{
            max-height: 60px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}
</style>