<template>
    <a-modal 
        v-model="visible" 
        title=""
        :width="600"
        :dialog-style="{ top: isMobile ? '0px' : '20px' }"
        :getContainer="getModalContainer"
        class="comment_modal"
        ref="commentModal"
        :afterClose="afterClose">
        <template #footer>
            <div class="w-full relative">
                <CommentInput
                    v-if="allowComments && comment"
                    :related_object="related_object"
                    :model="model"
                    :createFounder="createFounder"
                    :oneUpload="oneUpload"
                    :parent="comment"
                    modal
                    :blockLeft="blockLeft"
                    :setBlockLeft="setBlockLeft"
                    :getModalContainer="getInModalContainer"
                    inputPlaceholder="Ответить на комментарий"
                    :showEmoji="showEmoji"
                    :showFileUpload="showFileUpload"
                    :pushNewComment="pushNewComment"
                    :showUsers="showUsers" />
                <transition v-if="list.results.length" name="slide-up-fade">
                    <div 
                        v-if="!inputVisible" 
                        class="new_comment">
                        <a-badge 
                            :count="newComment"
                            :number-style="{
                                backgroundColor: '#1c65c0'
                            }">
                            <a-button
                                shape="circle"
                                flaticon
                                icon="fi-rr-angle-small-up"
                                @click="parentTopScroll()" />
                        </a-badge>
                    </div>
                </transition>
            </div>
        </template>
        <template #closeIcon>
            <a-button 
                type="ui" 
                ghost 
                flaticon 
                shape="circle"
                icon="fi-rr-cross" />
        </template>
        <a-spin :spinning="detailLoading" class="w-full">
            <template v-if="comment">
                <div 
                    class="current_comment" 
                    ref="currentComment" 
                    v-element-visibility="onElementVisibility">
                    <CommentItem 
                        :item="comment" 
                        :user="user"
                        modal
                        :deleteComment="deleteCurrentComment"
                        :setBlockLeft="setBlockLeft"
                        :openCheck="false"
                        :extendDrawerZIndex="1050"
                        :reply="false"
                        :related_object="related_object"
                        :model="model" />
                </div>
                <div
                    v-if="list.results.length"
                    class="pt-6">
                    <div 
                        v-for="(group, index) in sortedList" 
                        :key="index" 
                        class="comment_group">
                        <GroupLabel :group="group" />
                        <transition-group name="comment_list" tag="div">
                            <CommentItem
                                v-for="item in group.data"
                                :key="item.id"
                                :user="user"
                                :item="item"
                                :reply="false"
                                :setBlockLeft="setBlockLeft"
                                :blockLeft="blockLeft"
                                :extendDrawerZIndex="1050"
                                :toggleNewCommentCount="toggleNewCommentCount"
                                :updateNewComment="updateNewComment"
                                :deleteComment="deleteComment"
                                :related_object="related_object"
                                :pushNewComment="pushNewComment"
                                :model="model" />
                        </transition-group>
                    </div>
                </div>
                <infinite-loading 
                    ref="comment_m_infinity"
                    @infinite="getCommentInfinite"
                    :identifier="comment.id"
                    v-bind:distance="10">
                    <div 
                        slot="spinner"
                        class="flex items-center justify-center mt-3">
                        <a-spin />
                    </div>
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </template>
        </a-spin>
    </a-modal>
</template>

<script>
import eventBus from './eventBus.js'
import CommentItem from './CommentItem.vue'
import GroupLabel from './GroupLabel.vue'
import InfiniteLoading from 'vue-infinite-loading'
import CommentInput from './CommentInput.vue'
import { mapState } from 'vuex'
import { vElementVisibility } from '@vueuse/components'
export default {
    props: {
        related_object: {
            type: [String, Number],
            required: true
        },
        model: {
            type: String,
            required: false
        },
        pDeleteComment: {
            type: Function,
            default: () => {}
        },
        allowComments: {
            type: Boolean,
            default: true
        },
        createFounder: {
            type: Boolean,
            default: true
        },
        oneUpload: {
            type: Boolean,
            default: false
        },
        showEmoji: {
            type: Boolean,
            default: true
        },
        showFileUpload: {
            type: Boolean,
            default: true
        },
        showUsers: {
            type: Boolean,
            default: true
        },
        getModalContainer: {
            type: Function,
            default: () => document.body
        },
        setBlockLeft: {
            type: Function,
            default: () => {}
        },
        blockLeft: {
            type: Boolean,
            default: false
        }
    },
    directives: {
        ElementVisibility: vElementVisibility
    },
    components: {
        CommentItem,
        GroupLabel,
        InfiniteLoading,
        CommentInput
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        sortedList() {
            const list = [...this.list.results].map(item => {
                return {
                    ...item,
                    key: this.$moment(item.created_at).format('YYYY-MM-DD')
                }
            })

            const res = list.reduce((accumulator, currentValue, currentIndex, array, key = currentValue.key) => {
                const keyObjectPosition = accumulator.findIndex((item) => item.key === key)
                if (keyObjectPosition >= 0) {
                    accumulator[keyObjectPosition].data.push(currentValue)
                    return accumulator    
                } else {
                    return accumulator.concat({ data: [currentValue], key: key })
                }
            }, [])

            return res
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            visible: false,
            loading: false,
            comment: null,
            detailLoading: false,
            page: 0,
            inputVisible: true,
            slice_count: 0,
            newComment: 0,
            list: {
                results: [],
                count: 0,
                next: true
            }
        }
    },
    sockets: {
        create_comment({ data }) {
            if(this.visible && this.comment?.id === data.parent?.id) {
                this.pushNewComment({
                    ...data,
                    newComment: this.user?.id === data.author?.id ? false : true,
                    newVisible: this.user?.id === data.author?.id ? false : true
                })
            }
        },
        update_comment({data}) {
            if(this.visible && this.comment) {
                this.updateComment(data)
                if(this.comment.id === data.id) {
                    this.comment = data
                }
            }
        },
        delete_comment({ data }) {
            if(this.visible && this.comment) {
                this.deleteCommentHandler(data)
                if(this.comment.id === data) {
                    this.visible = false
                    this.$message.info('Данный комментарий удален')
                }
            }
        }
    },
    methods: {
        onElementVisibility(e) {
            this.inputVisible = e
        },
        getInModalContainer() {
            return this.$refs.currentComment
        },
        toggleNewCommentCount(type, id = null) {
            if(type === 'add') {
                this.newComment += 1
            }
            if(type === 'min') {
                if(this.newComment > 0)
                    this.newComment -= 1
                if(id) {
                    const index = this.list.results.findIndex(f => f.id === id)
                    if(index !== -1)
                        this.$delete(this.list.results[index], 'newVisible')
                }
            }
        },
        updateNewComment(id) {
            const index = this.list.results.findIndex(f => f.id === id)
            if(index !== -1) {
                this.$delete(this.list.results[index], 'newComment')
            }
        },
        updateComment(data) {
            if(this.list.results.length) {
                const index = this.list.results.findIndex(f => f.id === data.id)
                if(index !== -1)
                    this.$set(this.list.results, index, data)
            }
        },
        deleteCommentHandler(id) {
            const index = this.list.results.findIndex(f => f.id === id)
            if(index !== -1) {
                this.list.results.splice(index, 1)
                this.list.count -= 1
                if(this.slice_count > 0)
                    this.slice_count -= 1
            }
            const cParent = this.list.results.filter(f => f.parent?.id === id)
            if(cParent?.length) {
                cParent.forEach(item => {
                    const cIndex = this.list.results.findIndex(f => f.id === item.id)
                    if(cIndex !== -1) {
                        this.$set(this.list.results[cIndex].parent, 'text', 'Комментарий удалён')
                    }
                })
            }
        },
        async deleteCurrentComment(id) {
            try {
                await this.$http.post('/comments/delete/', { id })
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка удаления')
            }
        },
        async deleteComment(id) {
            try {
                await this.$http.post('/comments/delete/', { id })
                /*if(data === 'ok') {
                    this.list.count -= 1
                    const index = this.list.results.findIndex(f => f.id === id)
                    if(index !== -1) {
                        this.list.results.splice(index, 1)
                    }
                    this.spliceComment(id)
                }*/
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка удаления')
            }
        },
        pushNewComment(data) {
            this.list.results.unshift({
                ...data,
                created_at: this.$moment(data.created_at).add(-1, 'seconds').format()
            })
            this.list.count += 1
            this.slice_count += 1
        },
        clear() {
            this.inputVisible = true
            this.comment = null
            this.list = {
                results: [],
                count: 0,
                next: true
            }
            this.slice_count = 0
            this.page = 0
            this.newComment = 0
        },
        afterClose() {
            this.clear()
        },
        async getComment(id, cid = null) {
            try {
                this.detailLoading = true
                const { data } = await this.$http.get(`/comments/${id}/`)
                if(data) {
                    this.comment = data
                }
            } catch(error) {
                if(error?.detail === 'Страница не найдена.') {
                    this.visible = false
                    this.$message.info('Данный комментарий удален')
                    if(cid)
                        eventBus.$emit('set_comment_shaking', cid)
                }
                console.log(error)
            } finally {
                this.detailLoading = false
            }
        },
        async getCommentInfinite($state) {
            if(!this.loading && this.list.next) {
                try {
                    this.loading = true
                    this.page += 1

                    const params = {
                        page: this.page,
                        page_size: 15,
                        reverse: true,
                        parent: this.comment.id
                    }

                    if(this.slice_count)
                        params.slice_count = this.slice_count
                    if(this.model)
                        params.model = this.model
                    if(this.related_object)
                        params.related_object = this.related_object

                    const { data } = await this.$http.get('/comments/', { params })

                    if(data) {
                        this.list.count = data.count
                        this.list.next = data.next
                    }

                    if(data?.results?.length)
                        this.list.results = this.list.results.concat(data.results)
                        
                    if(this.list.next)
                        $state.loaded()
                    else
                        $state.complete()
                } catch(e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            } else {
                $state.complete()
            }
        },
        parentTopScroll() {
            if(this.$refs.commentModal?.$el?.children?.length) {
                const modalScroll = this.$refs.commentModal.$el.children[1]
                if(modalScroll)
                    modalScroll.scroll({top:0, behavior:'smooth'})
            }
        }
    },
    mounted() {
        eventBus.$on(`open_comment_detail_${this.related_object}`, (id, cid = null) => {
            if(this.comment?.id === id) {
                if(cid && this.list.results.length) {
                    const index = this.list.results.findIndex(f => f.id === cid)
                    if(index !== -1) {
                        if(this.list.results[index].shaking) {
                            this.$delete(this.list.results[index], 'shaking')
                            setTimeout(() => {
                                this.$set(this.list.results[index], 'shaking', true)
                            }, 5)
                        } else {
                            this.$set(this.list.results[index], 'shaking', true)
                        }
                    }
                }
            } else {
                if(!this.visible)
                    this.visible = true
                if(this.comment)
                    this.clear()
                this.getComment(id, cid)
            }
        })
    },
    beforeDestroy() {
        eventBus.$off(`open_comment_detail_${this.related_object}`)
    }
}
</script>

<style lang="scss" scoped>
.slide-up-fade-enter-active {
  transition: all .2s ease;
}
.slide-up-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-up-fade-enter, .slide-up-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.comment_modal{
    &::v-deep{
        .ant-modal-footer{
            position: sticky;
            bottom: 0px;
            z-index: 900;
            background: #ffffff;
            text-align: left;
            padding: 5px 10px;
            border-top: 1px solid var(--borderColor);
        }
    }
    .comment_group{
        .comment_list-enter-active, .comment_list-leave-active {
            transition: all 0.3s;
        }
        .comment_list-enter, .comment_list-leave-to {
            opacity: 0;
            transform: translateY(30px);
        }
    }
    .new_comment{
        position: absolute;
        top: -50px;
        right: 15px;
        &::v-deep{
            .ant-badge{
                .ant-badge-count{
                    font-size: 10px !important;
                    min-width: 17px;
                    height: 17px;
                    padding: 0 6px;
                    line-height: 17px;
                    right: initial;
                    left: 50%;
                    margin-left: -17px;
                    &.ant-badge-multiple-words{
                        margin-left: -23px;
                    }
                }
            }
        }
    }
}
</style>